import React from 'react';
import * as COMMON from 'root/_common';
import { withTranslation } from 'react-i18next';
import Login from './login';
import History from './history';

import { WebstaticService } from 'root/_services/webstatic.service';

import './styles.scss';

const setting = require('src/data/customer.json');

interface ILoginViewComponentProps {
  i18n?: any;
  t?: any;
}

interface ILoginViewComponentState {
    token: any;
    properties: any;
    formInvalid: boolean;
}

class ILoginViewComponent extends React.Component<ILoginViewComponentProps, ILoginViewComponentState> {
  constructor(props: any) {
    super(props);

    this.state = {
        token: '',
        properties: [],
        formInvalid: false
    };
  }

  public componentDidMount() {
    const token = localStorage.getItem('token');
    const properties = JSON.parse(localStorage.getItem('properties') || '{}');
    this.setState({
      token, 
      properties
    });
  }

  async checkLogin (email:any,password:any) {
    const { i18n } = this.props;
    const languageids = [ "", "nl",  "fr", "en",  "de" ];
    var langid = 0;
    languageids.forEach(function(lang, key){
      if (lang == i18n.language)
      {
        langid = key;
      }
    });
    const result = await WebstaticService.postLogin(setting.id,email,password,langid);
    console.log(result);
    if (result.data) {
      this.setState({
        token: result.data.token,
        properties: result.data.properties
      });
      localStorage.setItem('token', result.data.token);
      localStorage.setItem('properties', JSON.stringify(result.data.properties));
    } else {
      this.setState({
        formInvalid: true
      });
    }
  }
  
  logout () {
    this.setState({
      token: '',
      properties: []
    });
    localStorage.removeItem('token',);
    localStorage.removeItem('properties');
  }

  public render() {
    const { t } = this.props;
    if (this.state.token === null || this.state.token == '') {
        return (
          <div className='login-container'>
            <Login checkLogin={this.checkLogin.bind(this)} error={this.state.formInvalid} />
          </div>
        );
    }
    return (
      <>
        <History properties={this.state.properties} logout={this.logout.bind(this)} />
      </>
    );
  }
}

export default withTranslation(COMMON.TRANSLATION_DEFAULT)(ILoginViewComponent);
