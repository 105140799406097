import axios from 'axios';

const API_URL = "https://webstaticapi.omnicasa.com/api";
const token = 'QV5gYShfChSWB85D0LZ9LeVqzopJV1hV';

export const WebstaticService = {
    getRegion,
    getAutocompleteGeo,
    getGeoCity,
    postLogin
};

async function getRegion() {
    return await axios({
        method: 'GET',
        url: `${API_URL}/client/region`,
        auth: {
            username: 'omnicasa',
            password: token
        }
    }).then(function(response:any){
        return response.data;
    }).catch(function(error:any){
        return 'Error on Authentication';
    });
}

async function getAutocompleteGeo(search: string) {
    return await axios({
        method: 'GET',
        url: `${API_URL}/client/geolocation/autocomplete?search=${search}`,
        auth: {
            username: 'omnicasa',
            password: token
        }
    }).then(function(response:any){
        return response.data;
    }).catch(function(error:any){
        return 'Error on Authentication';
    });
}

async function getGeoCity() {
    return await axios({
        method: 'GET',
        url: `${API_URL}/client/geo-city`,
        auth: {
            username: 'omnicasa',
            password: token
        }
    }).then(function(response:any){
        return response.data;
    }).catch(function(error:any){
        return 'Error on Authentication';
    });
}

async function postLogin(customerid:any, email:any, password: any, langid: any) {
    return await axios({
        method: 'POST',
        url: `${API_URL}/client/owner/`,
        params: {
            'customer-id': customerid,
        },
        data: {
            email: email,
            password: password,
            langid: langid
        }
    }).then(function(response:any){
        return response.data;
    }).catch(function(error:any){
        return 'Error on Authentication';
    });
}