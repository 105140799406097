import React from 'react';
import * as COMMON from 'root/_common';
import { withTranslation } from 'react-i18next';
import { getAddressOfProperty } from 'root/_ultility';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { MdExpandMore } from "react-icons/md";
import Graph from "./graph";

import './styles.scss';

interface ILoginLoginViewComponentProps {
  t?: any;
  properties: any;
  logout: any;
}

interface ILoginLoginViewComponentState {
  selectedPropertyID: any;
}

class ILoginLoginViewComponent extends React.Component<ILoginLoginViewComponentProps, ILoginLoginViewComponentState> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedPropertyID: 0
    };
  }

  public showProperty(property:any) {
    const { t } = this.props;
    return <> 
        <div className='row'>
          <div className='col-sm-12'>
            <div className={"o-card" + (this.state.selectedPropertyID > 0 && this.state.selectedPropertyID == property.ID ? ' active' : '')}>
              <div className="media">
                <img className="img" src={property.MediumPictureItem.Url} />
              </div>
              <div className="card-content">
                <div className="">
                  <div>
                    <span className="type-description-text">{property.TypeDescription}</span>
                  </div>
                  <div>
                    <span className='refcontainer'>{t('Ref')}:&nbsp;<span className='ref'>{property.ID}</span></span>
                  </div>
                </div>
                <div className="address-container">
                <span className='address-text'>{getAddressOfProperty(property)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  }

  public AutomaticHistory(property:any) {
    const { t } = this.props;
    return <>
      <div className="history-list-container">
        <div className="history-list-row history-list-titlerow">
          <div>{t('OWNERLOGIN_DATE')}</div>
          <div>{t('OWNERLOGIN_TYPE')}</div>
          <div>{t('OWNERLOGIN_CONTACT')}</div>
          <div>{t('OWNERLOGIN_SUBJECT')} </div>
        </div>
        {property?.AutomaticHistory?.map((history: any) => {
          return <div className="history-list-row">
            <div>{history.Date}</div>
            <div>{history.TypeName}</div>
            <div>{history.ContactIdent}</div>
            <div>{history.Subject}</div>
          </div>
        })}   
      </div>
    </>;
  }

  public visitStatistics(property:any) {
    const { t } = this.props;
    return (<>
      <div className='history-list-container history-stats'>
        <div className="history-list-row history-list-titlerow history-stats-row">
            <div>{t('SOURCE_NAME')}</div>
            <div>{t('NumberOfVisitsInDay')}</div>
            <div>{t('NumberOfVisitsInWeek')}</div>
            <div>{t('NumberOfVisitsInMonth')}</div>
            <div>{t('NumberOfVisits')}</div>
        </div>
        {property?.visitStatistics?.map((visits: any) => {
          return <div className="history-list-row history-stats-row">
            <div>{visits.SourceName}</div>
            <div>{visits.NumberOfVisitsInDay}</div>
            <div>{visits.NumberOfVisitsInWeek}</div>
            <div>{visits.NumberOfVisitsInMonth}</div>
            <div>{visits.NumberOfVisits}</div>
          </div>
        })}
      </div>
    </>);
  }

  public CalandarHistory(property:any) {
    const { t } = this.props;
    return (<>
      <div className='history-list-container'>
        <div className="history-list-row history-list-titlerow">
            <div>{t('OWNERLOGIN_DATE')}</div>
            <div>{t('OWNERLOGIN_TYPE')}</div>
            <div>{t('OWNERLOGIN_CONTACT')}</div>
            <div>{t('OWNERLOGIN_STATUS')}</div>
        </div>
        {property?.CalendarHistories?.map((visits: any) => {
          return <div className="history-list-row">
            <div>{visits.StartTime}</div>
            <div>{visits.AppointmentName}</div>
            <div>{visits.ContactIdent}</div>
            <div>{visits.AppointmentStatus}</div>
            <div className="history-list-comment">{visits.Comment}</div>
          </div>
        })}
      </div>
    </>);
  }
  
  public MediaOnline(property:any) {
    const { t } = this.props;
    return (<>
      <div className='history-list-container'>
        <div className="history-list-row history-list-titlerow">
            <div>{t('OWNERLOGIN_DATE')}</div>
            <div>{t('OWNERLOGIN_TYPE')}</div>
            <div>{t('OWNERLOGIN_STATUS')}</div>
        </div>
        {property?.MediaOnlineHistories?.map((visits: any) => {
          return <div className="history-list-row">
            <div>{visits.Date}</div>
            <div>{visits.MediaName}</div>
            <div>{visits.TypeName}</div>
          </div>
        })}
      </div>
    </>);
  }

  public AppearanceHistories(property:any) {
    const { t } = this.props;
    return (<>
      <div className='history-list-container'>
        <div className="history-list-row history-list-titlerow">
            <div>{t('OWNERLOGIN_DATE')}</div>
            <div>{t('OWNERLOGIN_TYPE')}</div>
            <div>{t('OWNERLOGIN_COMMENT')}</div>
        </div>
        {property?.AppearanceHistories?.map((visits: any) => {
          return <div className="history-list-row">
            <div>{visits.AppearanceDate}</div>
            <div>{visits.TypeName}</div>
            <div>{visits.Text}</div>
          </div>
        })}
      </div>
    </>);
  }

  public showManager(property:any) {
    const { t } = this.props;
    return <div className='o-card'>
      <div className="media">
        <img className='img' src={!!property.ManagerPhoto ? property.ManagerPhoto : '/images/dummy.jpg'} alt={property.ManagerName} />
      </div>
      <div className='card-content'>
        <div className='card-contact-manager-info'>
        {property.ManagerName && property.ManagerName !== '' && <div className='contact-name'>{property.ManagerName}</div>}
        {property.ManagerFunction && property.ManagerFunction !== '' && property.ManagerFunction.length > 1 && <> <div className='contact-function' dangerouslySetInnerHTML={{
            __html: t(`${property.ManagerFunction.replace(/(?:\r\n|\r|\n|\\n)/g, '<br>')}`)
          }}></div></>}
        {property.ManagerBIV && property.ManagerBIV !== '' && property.ManagerBIV.replace(/\D/g,'').length == 6 && <><div className='contact-ipi'>{t('BIV')} {property.ManagerBIV.replace(/\D/g,'').split("", 3)}.{property.ManagerBIV.replace(/\D/g,'').substr(property.ManagerBIV.replace(/\D/g,'').length - 3)}</div></>}
        {property.ManagerEmail && property.ManagerEmail !== '' && <><div className='contact-email'><a href={'mailto:' + property.ManagerEmail} className='o-email'>{property.ManagerEmail}</a></div></>}
        {property.ManagerMobilePhone && property.ManagerMobilePhone !== '' && <><div className='contact-phone'><a href={'tel:' + property.ManagerMobilePhone} className='o-phone'>{property.ManagerMobilePhone}</a></div></>}
        </div>
    </div>
    </div>;
  }

  setPropertyID(id:any) {
    this.setState({ selectedPropertyID: id });
  }

  public render() {
    const { t, properties } = this.props;
    return (
      <>
      <div className='login_navigation'>
          <button className='btn btn-secondary' onClick={() => this.props.logout()}>{t('LOGOUT')}</button>
          {this.state.selectedPropertyID > 0 && <button className='btn btn-secondary' onClick={() => this.setPropertyID(0)}>{t('SHOW_ALL_PROPERTIES')}</button>}
      </div>
      <div className='history-container'>
        <h1>{t('HISTORY')}</h1>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='row property-list-section'>
            {this.state.selectedPropertyID == 0 && <div className='text-center'>
              <h2>{t('OWNERLOGIN_SELECT_PROPERTY')}</h2>
              <p>{t('OWNERLOGIN_SELECT_PROPERTY_INFO')}</p>
            </div>}
              {properties.map((property:any) => {
                if (this.state.selectedPropertyID > 0) {
                  return false;
                }
                return <div className='col-sm-12 col-lg-6 grid-item-wrapper' onClick={() => this.setPropertyID(property.ID)}>
                  {this.showProperty(property)}
                </div>
              })}
            </div>
          </div>
        </div>
        {this.state.selectedPropertyID > 0 &&
          properties.map((property:any) => {
              return this.state.selectedPropertyID == property.ID &&
              <>
                <div className='row'>
                  <div className='col-sm-12 col-lg-6'>
                    <h3>{t('PROPERTY')}</h3>
                    {this.showProperty(property)}   
                  </div>
                  <div className='col-sm-12 col-lg-6'>
                    <h3>{t('MANAGER')}</h3>
                    {this.showManager(property)}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-12 mainbar'>
                  <h2>{t('PAGE_VISITS')}</h2>
                  <Graph graphData={JSON.parse(property.AllPropertyTypesStatisticsGraph[0].Details)} />
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<MdExpandMore />}
                        aria-controls="stats-content"
                        id="stats-header"
                      >
                        <h3>{t('STATS')}</h3>
                      </AccordionSummary>
                      <AccordionDetails>                        
                        {this.visitStatistics(property)}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<MdExpandMore />}
                        aria-controls="calendar-content"
                        id="calendar-header"
                      >
                       <h3>{t('CALENDAR_HISTORIES')}</h3> 
                      </AccordionSummary>
                      <AccordionDetails>
                      {this.CalandarHistory(property)}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<MdExpandMore />}
                        aria-controls="communciations-content"
                        id="communciations-header"
                      >
                        <h3>{t('COMUNICATIONS')}</h3>
                      </AccordionSummary>
                      <AccordionDetails>
                      {this.AutomaticHistory(property)}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<MdExpandMore />}
                        aria-controls="mediaonline-content"
                        id="mediaonline-header"
                      >
                        <h3>{t('MEDIA_ONLINE')}</h3>
                      </AccordionSummary>
                      <AccordionDetails>
                      {this.MediaOnline(property)}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
                </>
              })}
      </div>
      </>
    );
  }
}

export default withTranslation(COMMON.TRANSLATION_DEFAULT)(ILoginLoginViewComponent);
