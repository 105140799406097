import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import * as COMMON from 'root/_common';
import { withTranslation } from 'react-i18next';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
  );

import './styles.scss';

interface IGraphViewComponentProps {
  t?: any;
  graphData: any;
}

interface IGraphViewComponentState {
}

class IGraphViewComponent extends React.Component<IGraphViewComponentProps, IGraphViewComponentState> {
    constructor(props: any) {
        super(props);

        this.state = {
        };
    }

    public render() {
        const { graphData } = this.props;
        const options = {
            scales: {
                y: {
                  beginAtZero: true
                }
              },
            responsive: true,
            plugins: {
                legend: {
                position: 'top' as const,
                },
            },
        };

        let labels: any[] = [];

        const datasets = graphData[Object.keys(graphData)[0]].MediaColumns.map((media:any) => {
            const mediad = graphData[Object.keys(graphData)[0]].Data.map((mediadata:any) => {
                if (!labels.includes(mediadata.Date)) {
                    labels.push(mediadata.Date.substring(0, 4) + "/" + mediadata.Date.substring(4, 6) + "/" + mediadata.Date.substring(6, 8));
                }
                return mediadata.Data.map((mediadatadata:any) => {
                    if (mediadatadata.MediaName == media) {
                        return mediadatadata.Visit;
                    }
                });
            });
            return {
                label: media,
                data: mediad,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            };
        });

        const data = {
            labels,
            datasets
          };
        return (<div className="chart">
            <Chart
                options={options}
                type="bar"
                data={data}
                height={'100px'}
            />
        </div>);
    }
}

export default withTranslation(COMMON.TRANSLATION_DEFAULT)(IGraphViewComponent);