import React from 'react';
import * as COMMON from 'root/_common';
import { withTranslation } from 'react-i18next';

import './styles.scss';

interface ILoginLoginViewComponentProps {
  t?: any;
  checkLogin?: any;
  error?: boolean;
}

interface ILoginLoginViewComponentState {
    
}

class ILoginLoginViewComponent extends React.Component<ILoginLoginViewComponentProps, ILoginLoginViewComponentState> {
  constructor(props: any) {
    super(props);

  }

  handleChange(e:any){
    e.preventDefault()
    this.props.checkLogin(
      e.target.email.value,
      e.target.password.value
    );
  };

  public render() {
    const { t, error } = this.props;
    console.log(this.props);
    return (
      <>
      <div className='site-contact-container'>
        <div className='contact-container'>
          <div className='o-card'>
            <div className='o-card-content'>
              <h1>{t('OWNER_LOGIN')}</h1>
              <form onSubmit={this.handleChange.bind(this)}>
                <div className='row'>
                    <div className='col-sm-12 col-item'>
                        <div className={`form-field-container`}>
                            <input type="email" name="email" className='input-text' />
                        </div>
                    </div>
                    <div className='col-sm-12 col-item'>
                        <div className={`form-field-container`}>
                            <input type="password" name="password" className='input-text' />
                        </div>
                    </div>
                    <div className='col-sm-12 col-item'>
                        <div className={`form-field-container`}>
                            <button className='btn btn-secondary' type="submit">{t('Login')}</button>
                        </div>
                    </div>
                    {error && <div className='col-sm-12'>
                      <p>{t('USER_NOT_FOUND')}</p>
                    </div>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default withTranslation(COMMON.TRANSLATION_DEFAULT)(ILoginLoginViewComponent);
