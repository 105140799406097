import React from 'react';
import Layout from '../components/layout';
import MetadataComponent from 'src/components/metadata';
import OwnerLogin from 'src/components/owner-login';
import { getObjectWithLanguage } from 'root/_ultility';
const setting = require('src/data/setting.json');
const siteInfo = setting.find((item: any) => item.name === 'site_info');

interface IPageProps {
    pageContext: any;
}

class PageTemplate extends React.Component<IPageProps, {}> {
    constructor(props: IPageProps) {
        super(props);
    }

    componentDidMount() {
        this.handleCss();
    }

    handleCss() {
        document.documentElement.style.setProperty('--color-primary', siteInfo.params.sitePrimaryColor);
        document.documentElement.style.setProperty('--color-secondary', siteInfo.params.siteSecondaryColor);
        document.documentElement.style.setProperty('--color-tertiary', siteInfo.params.siteTertiaryColor);
        document.documentElement.style.setProperty('--color-primary-text', siteInfo.params.siteColorPrimaryText);
        document.documentElement.style.setProperty('--color-background', siteInfo.params.siteColorBackGround);

    }   

    public render() {
        const { pageContext } = this.props;

        return (
            <>
                <MetadataComponent metadata={getObjectWithLanguage(pageContext.metadata)} />

                <Layout config={pageContext.layout} isHomePage={pageContext.isHomePage}>
                    <div style={{ minHeight: '466px' }} className={`page-${pageContext.name}`}>
                        <OwnerLogin />
                    </div>
                </Layout>
            </>
        );
    }
}

export default PageTemplate;

