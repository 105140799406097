import React from 'react';
import { Helmet } from 'react-helmet';
import { getContentWithLanguage, getCurrentLanguage } from 'root/_ultility';
const settingConfig = require('src/data/setting.json');
const customer = require('src/data/customer.json');
const paths = require('src/data/paths.json');

interface IMetadataComponentProps {
    metadata: any;
}

function getSiteName(): string {
    let sitename = '';
    const groupname = settingConfig.find((element: any) => {
        return element.groupName === 'customer';
    });
    if (groupname && groupname.params && groupname.params.RealName) {
        sitename = groupname.params.RealName;
    } else if (customer && customer.siteName) {
        sitename = customer.siteName;
    } else if (customer && customer.name) {
        sitename = customer.name;
    }
    return sitename;
}

function getPageName(): string {
    let pagetitle = '';
    const page = paths.find((element: any) => {
        return getContentWithLanguage(element) == location.pathname;
    });
    if (page && page.name)
    pagetitle = getContentWithLanguage(page.name);
    else if (page && page.breadcrumb[0].name)
    pagetitle = getContentWithLanguage(page.breadcrumb[0].name);
    else
    pagetitle = '';
    return pagetitle;
}

function MetadataComponent(props: IMetadataComponentProps) {
    const { metadata } = props;
    const sitename = getSiteName();
    const pagetitle = typeof location !== `undefined` ? getPageName() : '';
    const description = '';
    const currentUrl = typeof location !== `undefined` ? location.href : '';
    var defaultimage = typeof location !== `undefined` ? location.hostname + '/favicon.png' : '';
    const currlanguage = getCurrentLanguage();

    if (metadata.image)
    {
        defaultimage = metadata.image.url ? metadata.image.url : defaultimage;
    }

    return (
        <Helmet htmlAttributes={{ lang : currlanguage }}>
            <title>{(metadata.pageTitle) ? metadata.pageTitle : (pagetitle + ' | ' + sitename)}</title>
            <meta name="robots" content="all" />
            <meta name='title' content={(metadata.metadataTitle) ? metadata.metadataTitle : (pagetitle + ' | ' + sitename)} />
            <meta name='description' content={(metadata.metadataDescription) ? metadata.metadataDescription : description} />
            <meta name="thumbnail" content={(metadata.metadataOGImage) ? metadata.metadataOGImage : defaultimage} />
            <meta property='og:title' content={(metadata.metadataOGTitle) ? metadata.metadataOGTitle : (pagetitle + ' | ' + sitename)} />
            <meta property='og:type' content={(metadata.metadataOGType) ? metadata.metadataOGType : 'website'} />
            <meta property='og:url' content={currentUrl} />
            <meta property='og:image' content={(metadata.metadataOGImage) ? metadata.metadataOGImage : defaultimage} />
            <meta property='og:description' content={(metadata.metadataOGDescription) ? metadata.metadataOGDescription : description} />
            <meta name='twitter:title' content={(metadata.metadataTwitterTitle) ? metadata.metadataTwitterTitle : (pagetitle + ' | ' + sitename)} />
            <meta name='twitter:description' content={(metadata.metadataTwitterDescription) ? metadata.metadataTwitterDescription : description} />
            <meta name='twitter:site' content={(metadata.metadataTwitterSite) ? metadata.metadataTwitterSite : currentUrl} />
            <meta name='twitter:image' content={(metadata.metadataTwitterImage) ? metadata.metadataTwitterImage : defaultimage} />
            {metadata.googleVerification ? <meta name="google-site-verification" content={metadata.googleVerification} /> : ''}
            {metadata.facebookVerification ? <meta name="facebook-domain-verification" content={metadata.facebookVerification} /> : ''}
            {metadata.nortonSafeWeb ? <meta name="norton-safeweb-site-verification" content={metadata.nortonSafeWeb} /> : ''}
            <link rel='canonical' href={currentUrl}></link>
            <link rel="image_src" href={(metadata.metadataOGImage) ? metadata.metadataOGImage : defaultimage} />
            {metadata.schema && metadata.schema.map((item: any) => {
                return <script key="uniqueId1" type="application/ld+json">
                    { JSON.stringify(item) }
                </script>;
            })}
        </Helmet>
        
        
    );
}

export default MetadataComponent;
